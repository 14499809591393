@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

.channel-header {
	color: #fff;
	background-color: #727c86;
	border-color: #32383e;
}

.channel-row {
	color: #fff;
	
	background-color: #212529;
	border-color: #32383e;
}

.channel-header-empty, .channel-row-empty {
	background-color: #fff !important;
}